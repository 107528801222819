<template>
    <v-layout v-resize="onResize" class="d-flex flex-column">
        <v-sheet color="white" class="px-4 rounded-t">
            <div class="d-flex align-center pt-2 pb-4">
                <div class="d-flex align-start mr-4">
                    <v-icon x-large color="primary" >
                        {{ tableIcon }}
                    </v-icon>
                </div>
                <span class="text-h6 font-weight-regular">{{ tableName }}</span>

                <v-spacer></v-spacer>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            @click="setRefresh()"
                            :disabled="disableActionButtons"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Atualizar</span>
                </v-tooltip>

                <v-dialog v-model="dialogRefreshGrid" max-width="850px">
                    <v-card>
                        <div class="d-flex justify-center">
                            <v-card-title class="headline">Você possui edições não salvas. Tem certeza de que deseja continuar?</v-card-title>
                        </div>
                        <v-card-actions class="px-6 pb-6">
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="dialogRefreshGrid = false" class="mr-4">Cancelar</v-btn>
                            <v-btn color="warning" text @click="refresh(false)" class="mr-4">Continuar sem salvar</v-btn>
                            <v-btn color="primary" @click="refresh(true)">Salvar e continuar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn class="mr-4 pa-0"
                            @click="save()"
                            v-on="on"
                            min-width="48px"
                            :color="unsavedChanges ? 'warning' : 'primary'"
                            :disabled="disableActionButtons || readOnly || !unsavedChanges"
                            :loading="saving"
                        >
                            <v-icon> {{ unsavedChanges ? 'mdi-content-save-alert' : 'mdi-content-save' }}</v-icon>
                        </v-btn>
                    </template>
                    <span>Salvar</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            :disabled="disableActionButtons || readOnly"
                            @click="importSheet()"
                            :loading="importing"
                        >
                            <v-icon>mdi-file-excel</v-icon>
                        </v-btn>
                        <input
                            ref="uploader"
                            class="d-none"
                            type="file"
                            @change="onFileChanged"
                        >
                    </template>
                    <span>Importar Excel</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            :disabled="disableActionButtons"
                            @click="exportSheet()"
                            :loading="exporting"
                        >
                            <v-icon>mdi-microsoft-excel</v-icon>
                        </v-btn>
                    </template>
                    <span>Exportar Excel</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            @click="drawer = true"
                            :disabled="disableActionButtons"
                        >
                            <v-icon>mdi-comment-text-multiple</v-icon>
                        </v-btn>
                    </template>
                    <span>Comentários</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            @click="dialogApproval = true"
                            :disabled="disableActionButtons"
                        >
                            <v-icon>mdi-checkbox-marked-circle</v-icon>
                        </v-btn>
                    </template>
                    <span>Aprovar</span>
                </v-tooltip>

                <v-dialog v-model="dialogApproval" max-width="800" max-height="450" style="z-index: 1000;">
                    <v-card style="position: relative;">
                        <v-toolbar
                            dark
                            :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
                            style="position: sticky; top: 0; width: 100%; z-index: 5;" 
                            elevation="0"
                        >
                            <v-toolbar-title v-if="actualLevel !== null">
                                <span v-if="actualLevel.id_level > 0"> Aprovar nível - </span>
                                {{ actualLevel.id_level }}
                            </v-toolbar-title>
                            <v-spacer />

                            <v-tooltip bottom>
                                <template v-slot:activator="on">
                                    <v-btn color="transparent" class="mt-2"
                                        v-on="on"
                                        min-width="48px"
                                        @click="dialogApproval = false, justification = ''"
                                        depressed
                                    >
                                        <v-icon class="white--text">mdi-close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Fechar</span>
                            </v-tooltip>
                        </v-toolbar>
                        <v-container>
                            <v-layout justify-end wrap>
                                <v-flex xs12>
                                    <v-select
                                        v-model="selectedEvent"
                                        :items="options.find(option => option.column === 'id_event').items"
                                        readonly
                                        class="mx-2 my-1"
                                        item-text="text"
                                        item-value="id"               
                                        label="Evento"
                                        persistent-hint
                                    ></v-select>
                                    <v-select
                                        v-model="selectedPlanningGroup"
                                        :items="planningGroups"
                                        readonly
                                        class="mx-2 my-1"
                                        item-text="text"
                                        item-value="id"               
                                        label="Grupo de planejamento"
                                        persistent-hint
                                    ></v-select>
                                    <v-text-field
                                        v-model="justification"
                                        class="mx-2 mt-1"
                                        label="Justificativa"
                                    ></v-text-field>
                                </v-flex>
                                <v-btn class="my-2 mr-2" color="error" @click="approvalSelect(0)" :disabled="disableApprove || actualLevel.id_level === 1">
                                    Não aprovar
                                </v-btn>
                                <v-btn class="my-2" color="success" @click="approvalSelect(1)" :disabled="disableApprove || actualLevel.id_level === 'Todos os níveis aprovados'">
                                    Aprovar
                                </v-btn>
                                <v-flex>
                                    <v-data-table
                                        :headers="headers"
                                        :items="showHistory === true ? approvals : levels"
                                        :loading="loading"
                                        item-key="id_dynamic_report"
                                        sort-by="Usuário"
                                        class="pb-2"
                                        ref="dTable"
                                    >
                                        <!-- eslint-disable-next-line -->
                                        <template v-slot:item.id_status="{ item }">
                                            <v-layout>
                                                <v-icon
                                                    class="ml-4"
                                                    :color="item.id_status === 4 ? 'green' : 'red'"
                                                >
                                                    mdi-circle
                                                </v-icon>
                                            </v-layout>
                                        </template>
                                    </v-data-table>
                                </v-flex>
                            </v-layout>
                        </v-container>
                        <div class="light" style="position: sticky; bottom: 0; width: 100%;">
                            <v-card-actions class="px-6 py-6">
                                <v-checkbox
                                    v-model="showHistory"
                                    label="Exibir o Histórico de todas as Aprovações e Reprovações"
                                ></v-checkbox>
                            </v-card-actions>
                        </div>
                    </v-card>
                </v-dialog>

                <template>
                    <v-dialog v-model="dialogCloseGrid" max-width="850px">
                        <template v-slot:activator="{ on: dialog }" >
                            <v-tooltip top>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn color="primary" class="pa-0"
                                        v-on="{ ...tooltip, ...dialog}"
                                        min-width="48px"
                                        @click="closeGrid()"
                                    >
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Fechar</span>
                            </v-tooltip>
                        </template>
                        <v-card>
                            <div class="d-flex justify-center">
                                <v-card-title class="headline">Você possui edições não salvas. Tem certeza de que deseja continuar?</v-card-title>
                            </div>
                            <v-card-actions class="px-6 pb-6">
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="dialogCloseGrid = false" class="mr-4">Cancelar</v-btn>
                                <v-btn color="warning" text @click="$router.push('/')" class="mr-4">Continuar sem salvar</v-btn>
                                <v-btn color="primary" @click="save(), $router.push('/')">Salvar e continuar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </template>
            </div>

            <v-divider class="mb-2"></v-divider>

            <v-row no-gutters class="d-flex justify-start align-center pt-3">
                <v-col class="d-flex justify-start">
                    <v-autocomplete
                        name="event"
                        label="Evento"
                        :items="options.find(option => option.column === 'id_event').items"
                        item-value="text"
                        return-object
                        class="pa-0 mr-4"
                        v-model="event"
                        @change="setSelectEvent()"
                        @click="backupSelectEvent = event"
                        :loading="event ? false : true"
                        style="max-width: 300px !important; z-index: 1000 !important;"
                    />

                    <v-dialog v-model="dialogSelectEvent" max-width="850px">
                        <v-card>
                            <div class="d-flex justify-center">
                                <v-card-title class="headline">Você possui edições não salvas. Tem certeza de que deseja continuar?</v-card-title>
                            </div>
                            <v-card-actions class="px-6 pb-6">
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="closeDialogSelectEvent()" class="mr-4">Cancelar</v-btn>
                                <v-btn color="warning" text @click="selectEvent(false)" class="mr-4">Continuar sem salvar</v-btn>
                                <v-btn color="primary" @click="selectEvent(true)">Salvar e continuar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <v-divider vertical class="mr-4 mb-4"></v-divider>
                    
                    <span class="text-h6 mr-4"> {{ year1 ? year1.toString() : 'Ano 1' }} </span>
                    <div class="d-flex mt-1">
                        <v-checkbox
                            label="Mês"
                            :ripple="false"
                            class="pa-0 ma-0"
                            v-model="year1month"
                            @click="showAllMonths(1)"
                            :disabled="disableActionButtons"
                        />

                        <v-menu
                            bottom
                            left
                            :close-on-content-click="false"
                            style="z-index: 1000 !important;"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    x-small
                                    class="mr-4 pt-1"
                                    :disabled="disableActionButtons"
                                >
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>

                            <v-sheet class="px-4 py-2" max-width="260px">
                                <!-- @change="setYear1Months($event)" -->
                                <v-select
                                    v-model="year1months"
                                    :items="months"
                                    label="Escolha os meses"
                                    :item-text="item => item.text"
                                    @change="showMonth(1)"
                                    multiple
                                    return-object
                                >
                                    <template v-slot:selection="{ item, index }">
                                        <span v-if="index === 0">{{ item.text }}</span>

                                        <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                        <template v-if="index === 2">
                                            <span>...</span>
                                            <span class="grey--text text-caption pa-1">
                                                {{ `+ ${year1months.length - 2}` }}
                                            </span>
                                        </template>

                                    </template>
                                </v-select>

                                <div class="d-flex justify-end">
                                    <v-btn small color="primary" class="ma-0 mb-2"
                                        @click="year1month = true, showAllMonths(1)"
                                        :disabled="year1months.length === 12"
                                    >
                                        Selecionar todos
                                    </v-btn>
                                </div>
                            </v-sheet>
                        </v-menu>

                        <v-checkbox
                            label="Tri"
                            :ripple="false"
                            class="pa-0 ma-0"
                            v-model="year1quarter"
                            @click="showAllQuarters(1)"
                            :disabled="disableActionButtons"
                        />

                        <v-menu
                            bottom
                            left
                            :close-on-content-click="false"
                            style="z-index: 1000 !important;"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    x-small
                                    class="mr-4 pt-1"
                                    :disabled="disableActionButtons"
                                >
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>

                            <v-sheet class="px-4 py-2" max-width="240px">
                                <v-select
                                    v-model="year1quarters"
                                    :items="quarters"
                                    label="Escolha os trimestres"
                                    :item-text="item => item.text"
                                    @change="showQuarter(1)"
                                    multiple
                                    return-object
                                >
                                    <template v-slot:selection="{ item, index }">
                                        <span v-if="index === 0">{{ item.text }}</span>

                                        <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                        <template v-if="index === 2">
                                            <span>...</span>
                                            <span class="grey--text text-caption pa-1">
                                                {{ `+ ${year1quarters.length - 2}` }}
                                            </span>
                                        </template>

                                    </template>
                                </v-select>

                                <div class="d-flex justify-end">
                                    <v-btn small color="primary" class="ma-0 mb-2"
                                        @click="year1quarter = true, showAllQuarters(1)"
                                        :disabled="year1quarters.length === 4"
                                    >
                                        Selecionar todos
                                    </v-btn>
                                </div>
                            </v-sheet>
                        </v-menu>

                        <v-checkbox
                            label="Sem"
                            :ripple="false"
                            class="pa-0 ma-0"
                            v-model="year1semester"
                            @click="showAllSemesters(1)"
                            :disabled="disableActionButtons"
                        />

                        <v-menu
                            bottom
                            left
                            :close-on-content-click="false"
                            style="z-index: 1000 !important;"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    x-small
                                    class="mr-4 pt-1"
                                    :disabled="disableActionButtons"
                                >
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>

                            <v-sheet class="px-4 py-2" max-width="240px">
                                <v-select
                                    v-model="year1semesters"
                                    :items="semesters"
                                    label="Escolha os semestres"
                                    :item-text="item => item.text"
                                    @change="showSemester(1)"
                                    multiple
                                    return-object
                                >
                                    <template v-slot:selection="{ item, index }">
                                        <span v-if="index === 0">{{ item.text }}</span>

                                        <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                        <template v-if="index === 2">
                                            <span>...</span>
                                            <span class="grey--text text-caption pa-1">
                                                {{ `+ ${year1semesters.length - 2}` }}
                                            </span>
                                        </template>

                                    </template>
                                </v-select>

                                <div class="d-flex justify-end">
                                    <v-btn small color="primary" class="ma-0 mb-2"
                                        @click="year1semester = true, showAllSemesters(1)"
                                        :disabled="year1semesters.length === 2"
                                    >
                                        Selecionar todos
                                    </v-btn>
                                </div>
                            </v-sheet>
                        </v-menu>

                        <v-checkbox
                            label="Ano 1"
                            :ripple="false"
                            class="pa-0 ma-0 mr-4"
                            v-model="startingYear"
                            @click="showYear(1)"
                            :disabled="disableActionButtons"
                        />
                    </div>

                    <v-divider vertical class="mr-4 mb-4"></v-divider>

                    <span class="text-h6 mr-4"> {{ year2 ? year2.toString() : 'Ano 2' }} </span>
                    <div class="d-flex mt-1">
                        <v-checkbox
                            label="Mês"
                            :ripple="false"
                            class="pa-0 ma-0"
                            v-model="year2month"
                            @click="showAllMonths(2)"
                            :disabled="disableActionButtons"
                        />

                        <v-menu
                            bottom
                            left
                            :close-on-content-click="false"
                            style="z-index: 1000 !important;"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    x-small
                                    class="mr-4 pt-1"
                                    :disabled="disableActionButtons"
                                >
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>

                            <v-sheet class="px-4 py-2" max-width="240px">
                                <v-select
                                    v-model="year2months"
                                    :items="months"
                                    :item-text="item => item.text"
                                    @change="showMonth(2)"
                                    label="Escolha os meses"
                                    multiple
                                    return-object
                                >
                                    <template v-slot:selection="{ item, index }">
                                        <span v-if="index === 0">{{ item.text }}</span>

                                        <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                        <template v-if="index === 2">
                                            <span>...</span>
                                            <span class="grey--text text-caption pa-1">
                                                {{ `+ ${year2months.length - 2}` }}
                                            </span>
                                        </template>

                                    </template>
                                </v-select>

                                <div class="d-flex justify-end">
                                    <v-btn small color="primary" class="ma-0 mb-2"
                                        @click="year2month = true, showAllMonths(2)"
                                        :disabled="year2months.length === 12"
                                    >
                                        Selecionar todos
                                    </v-btn>
                                </div>
                            </v-sheet>
                        </v-menu>

                        <v-checkbox
                            label="Tri"
                            :ripple="false"
                            class="pa-0 ma-0"
                            v-model="year2quarter"
                            @click="showAllQuarters(2)"
                            :disabled="disableActionButtons"
                        />

                        <v-menu
                            bottom
                            left
                            :close-on-content-click="false"
                            style="z-index: 1000 !important;"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    x-small
                                    class="mr-4 pt-1"
                                    :disabled="disableActionButtons"
                                >
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>

                            <v-sheet class="px-4 py-2" max-width="240px">
                                <v-select
                                    v-model="year2quarters"
                                    :items="quarters"
                                    label="Escolha os trimestres"
                                    :item-text="item => item.text"
                                    @change="showQuarter(2)"
                                    multiple
                                    return-object
                                >
                                    <template v-slot:selection="{ item, index }">
                                        <span v-if="index === 0">{{ item.text }}</span>

                                        <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                        <template v-if="index === 2">
                                            <span>...</span>
                                            <span class="grey--text text-caption pa-1">
                                                {{ `+ ${year2quarters.length - 2}` }}
                                            </span>
                                        </template>

                                    </template>
                                </v-select>

                                <div class="d-flex justify-end">
                                    <v-btn small color="primary" class="ma-0 mb-2"
                                        @click="year2quarter = true, showAllQuarters(2)"
                                        :disabled="year2quarters.length === 4"

                                    >
                                        Selecionar todos
                                    </v-btn>
                                </div>
                            </v-sheet>
                        </v-menu>

                        <v-checkbox
                            label="Sem"
                            :ripple="false"
                            class="pa-0 ma-0"
                            v-model="year2semester"
                            @click="showAllSemesters(2)"
                            :disabled="disableActionButtons"
                        />

                        <v-menu
                            bottom
                            left
                            :close-on-content-click="false"
                            style="z-index: 1000 !important;"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    x-small
                                    class="mr-4 pt-1"
                                    :disabled="disableActionButtons"
                                >
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>

                            <v-sheet class="px-4 py-2" max-width="240px">
                                <v-select
                                    v-model="year2semesters"
                                    :items="semesters"
                                    label="Escolha os semestres"
                                    :item-text="item => item.text"
                                    @change="showSemester(2)"
                                    multiple
                                    return-object
                                >
                                    <template v-slot:selection="{ item, index }">
                                        <span v-if="index === 0">{{ item.text }}</span>

                                        <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                        <template v-if="index === 2">
                                            <span>...</span>
                                            <span class="grey--text text-caption pa-1">
                                                {{ `+ ${year2semesters.length - 2}` }}
                                            </span>
                                        </template>

                                    </template>
                                </v-select>

                                <div class="d-flex justify-end">
                                    <v-btn small color="primary" class="ma-0 mb-2"
                                        @click="year2semester = true, showAllSemesters(2)"
                                        :disabled="year2semesters.length === 2"
                                    >
                                        Selecionar todos
                                    </v-btn>
                                </div>
                            </v-sheet>
                        </v-menu>

                        <v-checkbox
                            label="Ano 2"
                            :ripple="false"
                            class="pa-0 ma-0 mr-4"
                            v-model="laterYear"
                            @click="showYear(2)"
                            :disabled="disableActionButtons"
                        />
                    </div>
                </v-col>
            </v-row>

            <v-row no-gutters class="d-flex justify-start align-center mt-2">
                <v-autocomplete
                    name="id_planning_group"
                    label="Grupo de planejamento"
                    :items="options.find(option => option.column === 'id_planning_group').items"
                    item-value="text"
                    return-object
                    class="pa-0 mr-4"
                    v-model="planningGroup"
                    @change="setSelectPlanningGroup()"
                    @click="backupSelectPlanningGroup = planningGroup"
                    :loading="planningGroup ? false : true"
                    style="max-width: 300px !important; z-index: 1000 !important;"
                />

                <v-dialog v-model="dialogSelectPlanningGroup" max-width="850px">
                    <v-card>
                        <div class="d-flex justify-center">
                            <v-card-title class="headline">Você possui edições não salvas. Tem certeza de que deseja continuar?</v-card-title>
                        </div>
                        <v-card-actions class="px-6 pb-6">
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="closeDialogSelectPlanningGroup()" class="mr-4">Cancelar</v-btn>
                            <v-btn color="warning" text @click="selectPlanningGroup(false)" class="mr-4">Continuar sem salvar</v-btn>
                            <v-btn color="primary" @click="selectPlanningGroup(true)">Salvar e continuar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-autocomplete
                    name="id_product"
                    label="Produto"
                    :items="options.find(option => option.column === 'id_product').items"
                    item-value="text"
                    return-object
                    class="pa-0 mr-4"
                    v-model="product"
                    @change="setSelectProduct()"
                    @click="backupSelectProduct = product"
                    :loading="product ? false : true"
                    style="max-width: 300px !important; z-index: 1000 !important;"
                />

                <v-dialog v-model="dialogSelectProduct" max-width="850px">
                    <v-card>
                        <div class="d-flex justify-center">
                            <v-card-title class="headline">Você possui edições não salvas. Tem certeza de que deseja continuar?</v-card-title>
                        </div>
                        <v-card-actions class="px-6 pb-6">
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="closeDialogSelectProduct()" class="mr-4">Cancelar</v-btn>
                            <v-btn color="warning" text @click="setProductId(false), refresh()" class="mr-4">Continuar sem salvar</v-btn>
                            <v-btn color="primary" @click="setProductId(true), refresh()">Salvar e continuar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-btn x-small text class="py-4 ma-0 mb-4" max-width="1%"
                    @click="backupSelectProduct = product, product = options.find(option => option.column === 'id_product').items[0], setSelectProduct()"
                    :disabled="product === -1 || loading ? true : options.find(option => option.column === 'id_product').items.indexOf(product) === 0"
                >
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>

                <v-btn x-small text class="py-4 ma-0 mb-4" max-width="1%"
                    @click="backupSelectProduct = product, product = options.find(option => option.column === 'id_product').items[options.find(option => option.column === 'id_product').items.indexOf(product) - 1], setSelectProduct()"
                    :disabled="product === -1 || loading ? true : options.find(option => option.column === 'id_product').items.indexOf(product) === 0"
                >
                    <v-icon>mdi-menu-left</v-icon>
                </v-btn>

                <v-btn x-small text class="py-4 ma-0 mb-4" max-width="1%"
                    @click="backupSelectProduct = product, product = options.find(option => option.column === 'id_product').items[options.find(option => option.column === 'id_product').items.indexOf(product) + 1], setSelectProduct()"
                    :disabled="product === -1 || options.find(option => option.column === 'id_product').items.indexOf(product) === options.find(option => option.column === 'id_product').items.length - 1 || loading"
                >
                    <v-icon>mdi-menu-right</v-icon>
                </v-btn>

                <v-btn x-small text class="py-4 ma-0 mb-4 mr-4" max-width="1%"
                    @click="backupSelectProduct = product, product = options.find(option => option.column === 'id_product').items[options.find(option => option.column === 'id_product').items.length - 1], setSelectProduct()"
                    :disabled="product === -1 || options.find(option => option.column === 'id_product').items.indexOf(product) === options.find(option => option.column === 'id_product').items.length - 1 || loading"
                >
                    <v-icon>mdi-chevron-right</v-icon>
                </v-btn>

                <v-autocomplete
                    name="id_plant"
                    label="Planta de produção"
                    :items="options.find(option => option.column === 'id_plant').items"
                    item-value="text"
                    return-object
                    class="pa-0 mr-4"
                    v-model="plant"
                    @change="setSelectPlant()"
                    @click="backupSelectPlant = plant"
                    :loading="plant ? false : true"
                    style="max-width: 300px !important; z-index: 1000 !important;"
                />

                <v-dialog v-model="dialogSelectPlant" max-width="850px">
                    <v-card>
                        <div class="d-flex justify-center">
                            <v-card-title class="headline">Você possui edições não salvas. Tem certeza de que deseja continuar?</v-card-title>
                        </div>
                        <v-card-actions class="px-6 pb-6">
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="closeDialogSelectPlant()" class="mr-4">Cancelar</v-btn>
                            <v-btn color="warning" text @click="selectPlant(false)" class="mr-4">Continuar sem salvar</v-btn>
                            <v-btn color="primary" @click="selectPlant(true)">Salvar e continuar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </v-sheet>

        <v-sheet v-if="loading" :height="windowSize.y - 64 - 240" class="rounded-b">
            <div class="d-flex justify-center align-center" style="width: 100%; height: 100%;">
                <v-btn large text :loading="loading" color="primary">
                    Carregando
                </v-btn>
            </div>
        </v-sheet>

        <v-sheet v-else-if="!loading && noDataMessage" :height="windowSize.y - 64 - 240" class="rounded-b">
            <div class="d-flex flex-column justify-center align-center" style="width: 100%; height: 100%;">
                <span>{{ noDataMessage }}</span>
                <v-btn v-if="noDataBtn.text"
                    large text color="primary" class="mt-4"
                    @click="$router.push(noDataBtn.path)"
                >
                    <v-icon small class="mr-2">mdi-plus</v-icon>
                    {{ noDataBtn.text }}
                </v-btn>
            </div>
        </v-sheet>

        <v-sheet v-else :height="windowSize.y - 64 - 240" class="rounded-b px-4">
            <HotTable ref="hotTable"
                licenseKey="1c81c-153e1-cb283-a4838-af03f"
                :data="objectData"
                :settings="settings"
                :columns="columns"
                :colHeaders="colHeaders"
                :rowHeaders="true"
                :hiddenColumns="hiddenColumns"
                :hiddenRows="hiddenRows"
                :contextMenu="contextMenu"
                :dropdownMenu="dropDownMenu"
                :manualColumnResize="true"
                :manualColumnFreeze="true"
                :multiColumnSorting="true"
                :bindRowsWithHeaders="true"
                :filters="true"
                fixedColumnsLeft="2"
                width="100%"
                height="100%"
                :columnHeaderHeight="50"
                :rowHeaderWidth="40"
                :trimWhitespace="false"
                :copyPaste="true"
                :renderAllRows="false"
            />
        </v-sheet>

        <CommentsMenu
            :drawer="drawer"
            @closeDrawer="drawer = false"
            :parentPayload="payload"
        />

        <v-dialog v-model="dialogSolver" max-width="900px"
            style="z-index: 5000 !important;"
        >
            <v-card>
                <v-card-title class="headline neutral">
                    <span class="headline">Atingir meta</span>
                </v-card-title>
                <v-card-text>
                    <ZnapSolverDialog 
                        :solver="solverProps"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-layout>
</template>

<script>
import { mapGetters } from 'vuex'
import { HotTable } from '@handsontable/vue'
import { registerLanguageDictionary, ptBR } from 'handsontable/i18n'
import numbro from 'numbro'
import languages from 'numbro/dist/languages.min'
import { HyperFormula } from 'hyperformula'
import moment from 'moment'
import CommentsMenu from '@/components/znap/CommentsMenu.vue'
import ZnapSolverDialog from '@/components/znap/ZnapSolverDialog.vue'

export default {
    name: 'Handsontable',

    components: {
        HotTable, CommentsMenu, ZnapSolverDialog
    },

    props: {
        options: { type: Array, required: true },
        // tableName: { type: String, required: true },
        // tableIcon: { type: String },
        // tableDescription: { type: String }
    },

    data() {
        return {
            // Hot Table
            objectData: [],
            settings: {
                formulas: {
                    engine: HyperFormula,
                },
                afterChange: (changes) => {
                    if (changes) {
                        changes.forEach(c => {
                            let rowIndex = c[0]
                            let colValue = c[1]
                            let newVal = c[2]
                            let oldVal = c[3]

                            if (oldVal === '') {
                                oldVal = null
                                this.objectData[rowIndex][colValue] = oldVal
                            }
                            
                            if (newVal != oldVal) {
                                this.editedRows.push(rowIndex)
                            }
                        })
                    }
                },
                language: ptBR.languageCode,
                fillHandle: {
                    autoInserRow: false
                },
                cells: (row, col) => {
                    var cellProperties = {}

                    const stylizedRow = this.stylizedRows[row] || { index: -1 }

                    if (row === stylizedRow.index) {
                        if (stylizedRow.className) {
                            cellProperties.className = stylizedRow.className
                        }

                        if (!this.readOnlyColumns.includes(col)) {
                            cellProperties.readOnly = !stylizedRow.readOnly ? false : true
                        } else {
                            cellProperties.className += ` border-sides-1 font-bold`
                        }

                        if (stylizedRow.percent) {
                            cellProperties.numericFormat = {
                                pattern: {
                                    output: "percent",
                                    negative: "parenthesis",
                                    mantissa: 2,
                                },
                                culture: ptBR.languageCode
                            }
                        }
                    }

                    if (this.readOnly) {
                        cellProperties.readOnly = this.readOnly
                    }

                    return cellProperties
                }
            },
            columns: [],
            colHeaders: [],
            rowHeaders: [],
            stylizedRows: [],
            hiddenColumns: {},
            hiddenRows: { rows: [], indicators: true },
            contextMenu: [
                'copy',
                'hidden_rows_hide',
                'hidden_rows_show',
                'hidden_columns_hide',
                'hidden_columns_show',
                'freeze_column',
                'unfreeze_column',
            ],
            dropDownMenu: [
                'filter_by_condition',
                'filter_by_condition2',
                'filter_action_bar',
                'filter_operators',
                'filter_by_value'
            ],

            dialogSolver: false,
            solverProps: {
                columnName: null,
                column: null,
                row: null,
                rowProps: null,
                targetRow: null,
                targetValue: null,
                changeRow: null,
            },

            // Options
            payload: {
                id_event: null,
                id_planning_group: null,
                id_product: null,
                id_plant: null,
                id_account_group_version: null,
            },
            event: null,
            planningGroup: null,
            product: null,
            plant: null,
            accountGroupVersion: null,
            year1: null,
            year2: null,

            // Date filters
            months: [],
            quarters: [],
            semesters: [],
            startingYear: true,
            startingMonthIndex: null,
            year1month: true,
            year1months: [],
            year1quarter: true,
            year1quarters: [],
            year1semester: true,
            year1semesters: [],
            laterYear: true,
            year2month: true,
            year2months: [],
            year2quarter: true,
            year2quarters: [],
            year2semester: true,
            year2semesters: [],
            yearMonthInputStart: null,
            fiscalYearStartMonth: null,
            readOnlyColumns: [],

            // Unsaved changes
            editedRows: [],
            dialogCloseGrid: false,
            dialogRefreshGrid: false,
            dialogSelectEvent: false,
            backupSelectEvent: null,
            dialogSelectPlanningGroup: false,
            backupSelectPlanningGroup: null,
            dialogSelectProduct: false,
            backupSelectProduct: null,
            dialogSelectPlant: false,
            backupSelectPlant: null,

            windowSize: { x: 0, y: 0 },
            loading: true,
            saving: false,
            noDataMessage: null,
            noDataBtn: { path: null, text: null },

            hotInstance: null,

            drawer: false,
            exporting: false,
            importing: false,

            // workflow
            readOnly: false,
            dialogApproval: false,
            justification: '',
            headers: [
                {
                    text: 'Semáforo', value: 'id_status'
                },
                {
                    text: 'Nível', value: 'approval_flow_level_description'
                },
                {
                    text: 'Status', value: 'status'
                },
                {
                    text: 'Usuário', value: 'name'
                },
                {
                    text: 'Data', value: 'date'
                },
                {
                    text: 'Justificativa', value: 'justification'
                }
            ],
            levels: [],
            planningGroups: [],
            selectedPlanningGroup: null,
            selectedEvent: null,
            showHistory: false,
            approvals: null,
            userGroups: [],
            actualLevel: {
                id_level: 0,
                id_group: 0
            },
            disableApprove: true
        }
    },

    mounted() {
        registerLanguageDictionary(ptBR)
        numbro.registerLanguage(languages['pt-BR'])
        this.addPreventCloseHandler()
    },

    updated() {
        if (this.$refs.hotTable) {
            if (this.hotInstance === null) {
                this.hotInstance = this.$refs.hotTable.hotInstance
                this.hotInstance.render()
            }
        }
    },

    destroyed() {
        this.removePreventCloseHandler()
    },

    computed: {
        ...mapGetters('auth', ['getUser', 'getMenu']),

        tableName() {
            const tableName = this.getTableInfo().tableName

            return tableName ? tableName : 'Erro ao listar a tabela'
        },

        tableIcon() {
            const tableIcon = this.getTableInfo().tableIcon

            return tableIcon ? tableIcon : 'mdi-view-list'
        },

        disableActionButtons() {
            let disableActionButtons = false
            for (let key in this.payload) {
                if (this.payload[key] === null) {
                    disableActionButtons = true
                }
            }

            return disableActionButtons
        },

        unsavedChanges() {
            if (this.editedRows.length > 0) {
                return true
            } else {
                return false
            }
        },
    },

    watch: {
        event (val) {
            this.selectedEvent = val.id
            try {
                this.$http.post(this.$ipApprovalFlow + 'planning-group' + '/list-options',
                    {
                        filter: {
                            conditions: [
                                {
                                    AndOr: "AND",
                                    column: "id_planning_group_version",
                                    operator: "=",
                                    value: val.id_planning_group_version
                                }
                            ]
                        }
                    }
                ).then((res) => {
                    this.planningGroups = res.data.rows
                })
            } catch (err) {
                this.$fnError(err)
            }
        },

        planningGroup (val) {
            if (val){
                this.selectedPlanningGroup = val.id
                this.levels = []
                this.approvals = []
                this.approvalListLevels()
                this.approvalHistory()
            }
        }
    },

    async beforeMount() {
        this.listUserGroups()

        try {
            let filter = {
                conditions: [
                    {
                        AndOr: "AND",
                        column: "id_module",
                        operator: "=",
                        value: 7
                    },
                    {
                        AndOr: "OR",
                        column: "id_planning_function",
                        operator: "=",
                        value: 5
                    },
                    {
                        AndOr: "OR",
                        column: "id_planning_function",
                        operator: "=",
                        value: null
                    }
                ]
            }

            const eventOption = this.options.find(option => option.column === 'id_event')
            const eventRes = await this.$http.post(eventOption.endpoint[0] + eventOption.endpoint[1] + '/list-options', { filter })
            if (eventRes.data.rows.length) {
                eventRes.data.rows.forEach((row, index) => {
                    eventOption.items.push(row)
                    if (index === 0) {
                        this.event = row
                        this.payload.id_event = this.event.id
                        this.payload.id_account_group_version = row.id_account_group_version
                        this.payload.id_planning_group_version = row.id_planning_group_version
                        this.year1 = row.fiscal_year
                        this.yearMonthInputStart = row.year_month_input_start
                        this.fiscalYearStartMonth = row.fiscal_year_start_month
                        this.startingMonthIndex = row.fiscal_year_start_month - 1
                        this.year2 = this.year1 + 1
                    }
                })

                this.setDateColumns()

                filter = {
                    conditions: [
                        {
                            AndOr: "AND",
                            column: "id_planning_group_version",
                            operator: "=",
                            value: this.payload.id_planning_group_version
                        }
                    ]
                }

                const planningGroupOption = this.options.find(option => option.column === 'id_planning_group')
                const planningGroupRes = await this.$http.post(planningGroupOption.endpoint[0] + planningGroupOption.endpoint[1] + '/list-options', { filter })
                if (planningGroupRes.data.rows.length) {
                    planningGroupRes.data.rows.forEach((row, index) => {
                        planningGroupOption.items.push(row)
                        if (index === 0) {
                            this.planningGroup = row
                            this.payload.id_planning_group = this.planningGroup.id
                        }
                    })

                    filter = {
                        conditions: [
                            {
                                AndOr: "AND",
                                column: "id_planning_group",
                                operator: "=",
                                value: this.payload.id_planning_group
                            },
                            {
                                AndOr: "AND",
                                column: "fiscal_year",
                                operator: "=",
                                value: this.year1
                            }
                        ]
                    }

                    const productOption = this.options.find(option => option.column === 'id_product')
                    const productRes = await this.$http.post(productOption.endpoint[0] + productOption.endpoint[1] + '/list-options', { filter })
                    if (productRes.data.rows.length) {
                        productRes.data.rows.forEach((row, index) => {
                            productOption.items.push(row)
                            if (index === 0) {
                                this.product = row
                                this.payload.id_product = this.product.id_product
                            }
                        })
                    } else {
                        return this.noProductsFound()
                    }
                } else {
                    return this.noPlanningGroupsFound()
                }

                const plantOption = this.options.find(option => option.column === 'id_plant')
                const plantRes = await this.$http.post(plantOption.endpoint[0] + plantOption.endpoint[1] + '/list-options', { })
                if (plantRes.data.rows.length) {
                    plantRes.data.rows.forEach((row, index) => {
                        plantOption.items.push(row)
                        if (index === 0) {
                            this.plant = row
                            this.payload.id_plant = this.plant.id
                        }
                    })

                    return this.loadGrid(this.payload)
                } else {
                    return this.noPlantsFound()
                }
            } else {
                return this.noEventsFound()
            }
        } catch (err) {
            this.$fnError(err)
        }
    },

    methods: {
        getTableInfo() {
            let tableInfo = {
                tableName: '',
                tableIcon: '',
            }

            for (let i = 0; i < this.getMenu.length; i++) {
                let submenu = this.getMenu[i].menus.find(m => m.frontend_route_path === this.$route.path)
                if (submenu) {
                    tableInfo.tableIcon = this.getMenu[i].icon
                    tableInfo.tableName = submenu.label
                }
            }

            return tableInfo
        },

        addPreventCloseHandler() {
            window.addEventListener('beforeunload', this.preventCloseWindow)
        },

        removePreventCloseHandler() {
            window.removeEventListener('beforeunload', this.preventCloseWindow)
        },

        preventCloseWindow(e) {
            if (this.unsavedChanges) {
                e.preventDefault()
                e.returnValue = ''
            }
        },

        async loadGrid(payload) {
            this.loading = true
            this.hotInstance = null
            let loadGrid = true
            this.editedRows = []

            for (let key in payload) {
                if (payload[key] === null) {
                    loadGrid = false
                }
            }

            if (loadGrid) {
                this.noDataMessage = null
                this.noDataBtn = { path: null, text: null }

                try {
                    const productionPlanningRes = await this.$http.post(this.$ipProductionPlanning + 'production-planning' + '/list', { ...payload })
                    if (productionPlanningRes) {
                        let _colHeaders = []
                        let _columns = []
                        let resColumns = productionPlanningRes.data.columns
                        this.formulas = productionPlanningRes.data.formulas

                        productionPlanningRes.data.headers.forEach((header) => {
                            let headerIndex = parseInt(header.value.slice(5, 7), 10)
                            let columnIndex = resColumns.findIndex(column => column.columnAlias === header.value)

                            let headerText = header.text

                            if (resColumns[columnIndex].columnType === 'INT' || resColumns[columnIndex].columnType === 'DECIMAL') {
                                if (header.value === 'value00') {
                                    let values = header.text.split(' ')
                                    headerText = `${values[0]} ${values[1] || ''}`
                                }

                                let year = null
                                if (headerIndex > 0 && headerIndex <= 12) {
                                    if (this.months[headerIndex - 1].id >= this.fiscalYearStartMonth) {
                                        year = this.year1
                                    } else {
                                        year = this.year2
                                    }
                                    headerText = `${this.months[headerIndex - 1].text.slice(0,3)}/${year.toString().slice(2,4)}`
                                } else if (headerIndex >= 13 && headerIndex <= 24) {
                                    if (this.months[headerIndex - 1 - 12].id >= this.fiscalYearStartMonth) {
                                        year = this.year2
                                    } else {
                                        year = parseInt(this.year2, 10) + 1
                                    }
                                    headerText = `${this.months[headerIndex - 1 - 12].text.slice(0,3)}/${year.toString().slice(2,4)}`
                                }
                            }
                            _colHeaders.push(headerText)

                            let readOnly = false
                            // Aqui modifica readOnly de acordo com id_status
                            this.readOnly ? readOnly = true: readOnly = false
                            this.event.id_status === 3 ? readOnly = true : false
                            this.event.id_status === 3 ? this.readOnly = true : false

                            if (header.editable === false) {
                                readOnly = true
                            }

                            let type = null
                            let className = ''
                            let numericFormat = null
                            if (resColumns[columnIndex].columnType === 'INT' || resColumns[columnIndex].columnType === 'DECIMAL') {
                                type = 'numeric'
                                className += ' htRight'
                                numericFormat = {
                                    pattern: {
                                        thousandSeparated: true,
                                        negative: "parenthesis",
                                        mantissa: 2,
                                    },
                                    culture: ptBR.languageCode
                                }
                            } else {
                                type = 'text'
                                className += ' htLeft'
                            }

                            let hide = false
                            if (header.hideColumn) {
                                hide = true
                            }

                            const totalColumns = [
                                'year1quarter1', 'year1quarter2', 'year1quarter3', 'year1quarter4', 'year1semester1', 'year1semester2', 'year1',
                                'year2quarter1', 'year2quarter2', 'year2quarter3', 'year2quarter4', 'year2semester1', 'year2semester2', 'year2'
                            ]

                            if (totalColumns.includes(header.value)) {
                                className += ' border-sides-1 font-bold'
                            }

                            _columns.push({ data: header.value, readOnly, type, className, numericFormat, hide, columnLetter: header.columnLetter })
                        })
                        this.colHeaders = _colHeaders
                        this.columns = _columns

                        this.setHiddenColumns()
                        this.setDateReadOnlyColumns()

                        let _rowHeaders = []
                        let _objectData = []
                        this.stylizedRows = []

                        productionPlanningRes.data.rows.forEach((row, index) => {
                            _rowHeaders.push('')

                            let rowProps = {
                                index,
                                row,
                                className: '',
                                format: row.format,
                                readOnly: false,
                                percent: false,
                            }

                            if (row.indent) {
                                rowProps.className += ' indentedRow'
                                row['account_group_description'] = ' '.repeat(row.indent) + row['account_group_description']
                            }

                            if (row.format.includes('%')) {
                                rowProps.percent = true
                            }

                            if (row.data_type === 'INT' || row.data_type === 'DECIMAL') {
                                for (let i = 0; i <= 24; i++) {
                                    if (i < 10) {
                                        row[`value0${i}`] = parseFloat(row[`value0${i}`], 10) || null
                                    } else {
                                        row[`value${i}`] = parseFloat(row[`value${i}`], 10) || null
                                    }
                                }
                            }

                            if (row.total > 0) {
                                // rowProps.className += ' formulaRow'

                                let foundFormula = this.formulas.find(formula => formula.system_id === row.system_id)
                                let startIndex = this.formulas.indexOf(foundFormula)
                                let endIndex = this.formulas.indexOf(foundFormula) + 23

                                if (foundFormula) {
                                    for (let i = startIndex; i <= endIndex; i++) {
                                        row[this.formulas[i].column] = this.formulas[i].excelFormula
                                    }
                                }
                            }

                            if (row.css.length) {
                                rowProps.className += ` ${row.css}`
                            }

                            if (row.editable != 1) {
                                rowProps.readOnly = true
                            }

                            this.stylizedRows.push(rowProps)
                            
                            _objectData.push(row)
                        })
                        this.rowHeaders = _rowHeaders
                        this.objectData = _objectData

                        if (!this.objectData.length) {
                            this.noDataMessage = 'Não há dados disponíveis'
                        }

                        this.loading = false
                    }
                } catch (err) {
                    this.loading = false
                    this.$fnError(err)
                }
            } else {
                this.loading = false
            }
        },

        solver(column = 0, targetRow = 0, targetValue = 0, changeRow = 0){
            function ruleOf3 (initValChange, currTargetVal, initValTarget){
                if(initValTarget === 0 ) return 0
                return ( initValChange * currTargetVal ) / initValTarget
            }
            // TODO: ver com ademir se é assim que posso me referenciar a table, par apode referenciar as celulas
            // OK! isso aí, Luc.
            const table = this.hotInstance

            let initialValueFromTargetRow =  parseFloat(table.getDataAtCell(targetRow, column))
            let initialValueFromChangeRow =  parseFloat(table.getDataAtCell(changeRow, column))

            let calculatedChangeRow = ruleOf3( initialValueFromChangeRow , targetValue , initialValueFromTargetRow )
            if(!calculatedChangeRow) return { interations:0, newValueFromChangeRow: initialValueFromChangeRow , newValueFromTargetRow: initialValueFromTargetRow }

            table.setDataAtCell(changeRow, column, calculatedChangeRow)

            // TODO: verificar se precisa chamar alguma função para reclacular as formulas da planilha e se é sincrono ou assincrono

            let condition = true
            let interations = 0

            while (condition) {
                interations++
                let newValueFromTargetRow =  parseFloat(table.getDataAtCell(targetRow, column))
                let newValueFromChangeRow =  parseFloat(table.getDataAtCell(changeRow, column))

                let dif = targetValue - newValueFromTargetRow

                if( Math.abs(dif) <= 0.5 ){
                    condition = false
                    table.setDataAtCell(changeRow, column, initialValueFromChangeRow)
                    return { interations, newValueFromChangeRow, newValueFromTargetRow }
                }
                // TODO: testar o algoritimo e ajustar os incrementos e decrementos
                if( dif > 0 && dif <= 1 ) newValueFromChangeRow = newValueFromChangeRow + 0.1
                if( dif > 1 ) newValueFromChangeRow = newValueFromChangeRow + 1
                if( dif < 0 && dif >= -1 ) newValueFromChangeRow = newValueFromChangeRow - 0.1
                if( dif < -1 ) newValueFromChangeRow = newValueFromChangeRow - 1

                table.setDataAtCell(changeRow, column, newValueFromChangeRow)
                // TODO: verificar se precisa chamar alguma função para reclacular as formulas da planilha e se é sincrono ou assincrono

                if(interations > 10000) condition = false
            }
            return { interations, newValueFromChangeRow: initialValueFromChangeRow , newValueFromTargetRow: initialValueFromTargetRow }
        },

        setDateColumns() {
            this.startingYear = true
            this.year1month =  true
            this.year1quarter =  true
            this.year1semester =  true
            this.laterYear =  true
            this.year2month =  true
            this.year2quarter =  true
            this.year2semester =  true

            this.months = this.$calendar.months()
            this.quarters = this.$calendar.quarters()
            this.semesters = this.$calendar.semesters()
            let _months = []
            this.months.forEach(month => {
                if (month.id - 1 < this.startingMonthIndex) {
                    _months.splice(this.startingMonthIndex + month.id - 1, 0, month)
                } else {
                    _months.splice(month.id - 1 - this.startingMonthIndex, 0, month)
                }
            })
            this.months = _months
            this.year1months = this.months
            this.year2months = this.months
            this.year1quarters = this.quarters
            this.year2quarters = this.quarters
            this.year1semesters = this.semesters
            this.year2semesters = this.semesters
        },

        setHiddenColumns() {
            let _hiddenColumns = { columns: [], copyPasteEnabled: true }

            this.columns.forEach((column, index) => {
                if (column.hide) {
                    _hiddenColumns.columns.push(index)
                }
            })

            this.hiddenColumns = _hiddenColumns

            let cols = []

            for (let i = 1; i <= 12; i++) {
                let col = null
                let foundMonth = this.year1months.find(m => m.id === i)
                
                if (!foundMonth) {
                    if (i < 10) {
                        col = this.columns.findIndex(c => c.data === `value0${i}`)
                    } else {
                        col = this.columns.findIndex(c => c.data === `value${i}`)
                    }
                }

                if (col) {
                    cols.push(col)
                }
            }

            for (let i = 13; i <= 24; i++) {
                let col = null
                let foundMonth = this.year2months.find(m => m.id === i - 12)
                
                if (!foundMonth) {
                    col = this.columns.findIndex(c => c.data === `value${i}`)
                }

                if (col) {
                    cols.push(col)
                }
            }

            for (let i = 1; i <= 4; i++) {
                let col = null
                let foundQuarter = this.year1quarters.find(q => q.id === i)

                if (!foundQuarter) {
                    col = this.columns.findIndex(c => c.data === `year1quarter${i}`)
                }

                if (col) {
                    cols.push(col)
                }
            }

            for (let i = 1; i <= 4; i++) {
                let col = null
                let foundQuarter = this.year2quarters.find(q => q.id === i)
                
                if (!foundQuarter) {
                    col = this.columns.findIndex(c => c.data === `year2quarter${i}`)
                }

                if (col) {
                    cols.push(col)
                }
            }

            for (let i = 1; i <= 2; i++) {
                let col = null
                let foundSemester = this.year1semesters.find(s => s.id === i)
                
                if (!foundSemester) {
                    col = this.columns.findIndex(c => c.data === `year1semester${i}`)
                }

                if (col) {
                    cols.push(col)
                }
            }

            for (let i = 1; i <= 2; i++) {
                let col = null
                let foundSemester = this.year2semesters.find(s => s.id === i)
                
                if (!foundSemester) {
                    col = this.columns.findIndex(c => c.data === `year2semester${i}`)
                }

                if (col) {
                    cols.push(col)
                }
            }

            for (let i = 1; i <= 2; i++) {
                let col = null

                if (i === 1) {
                    if (!this.startingYear) {
                        col = this.columns.findIndex(c => c.data === `year${i}`)
                    }
                } else if (i === 2) {
                    if (!this.laterYear) {
                        col = this.columns.findIndex(c => c.data === `year${i}`)
                    }
                }

                if (col) {
                    cols.push(col)
                }
            }

            this.hiddenColumns.columns.push(...cols)
        },

        setDateReadOnlyColumns() {
            const monthInputStart = parseInt((this.yearMonthInputStart).toString().slice(4, 6))
            const difference = monthInputStart - this.fiscalYearStartMonth

            this.readOnlyColumns = []

            // verify if month index is before company fiscal year
            if (difference > 0) {
                for (let i = 1; i <= difference; i++) {
                    let col = null

                    if (i < 10) {
                        col = `value0${i}`
                    } else {
                        col = `value${i}`
                    }

                    let foundColumn = this.columns.find(c => c.data === col)
                    let foundColumnIndex = this.columns.findIndex(c => c.data === col)
                    if (foundColumn) {
                        foundColumn.readOnly = true
                        foundColumn.className += ' read-only-column'
                        this.readOnlyColumns.push(foundColumnIndex)
                    }
                }
            }

            const totalColumns = [
                'year1quarter1', 'year1quarter2', 'year1quarter3', 'year1quarter4', 'year1semester1', 'year1semester2', 'year1',
                'year2quarter1', 'year2quarter2', 'year2quarter3', 'year2quarter4', 'year2semester1', 'year2semester2', 'year2'
            ]

            // verify if column is readOnly
            this.columns.forEach((c, i) => {
                if (totalColumns.includes(c.data) && c.readOnly){
                    this.readOnlyColumns.push(i)
                }
            })
        },

        showAllMonths(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let cols = []
            let col = null

            if (year === 1) {
                for (let i = 1; i <= 12; i++) {
                    if (i < 10) {
                        col = this.hotInstance.propToCol(`value0${i}`)
                    } else {
                        col = this.hotInstance.propToCol(`value${i}`)
                    }
                    cols.push(col)
                }

                if (this.year1month) {
                    this.year1months = this.months
                    plugin.showColumns(cols)
                } else {
                    this.year1months = []
                    plugin.hideColumns(cols)
                }
            } else if (year === 2) {
                for (let i = 13; i <= 24; i++) {
                    col = this.hotInstance.propToCol(`value${i}`)
                    cols.push(col)
                }

                if (this.year2month) {
                    this.year2months = this.months
                    plugin.showColumns(cols)
                } else {
                    this.year2months = []
                    plugin.hideColumns(cols)
                }
            }

            this.hotInstance.render()
        },

        showMonth(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let col = null

            if (year === 1) {
                for (let i = 1; i <= 12; i++) {
                    let index = i + this.startingMonthIndex
                    if (index > 12) index -= 12
                    let foundMonth = this.year1months.find(month => month.id === index)
                    if (i < 10) {
                        col = this.hotInstance.propToCol(`value0${i}`)
                    } else {
                        col = this.hotInstance.propToCol(`value${i}`)
                    }

                    if (foundMonth) {
                        plugin.showColumn(col)
                    } else {
                        plugin.hideColumn(col)
                    }
                }
            } else if (year === 2) {
                for (let i = 13; i <= 24; i++) {
                    let index = i - 12 + this.startingMonthIndex
                    if (index > 12) index -= 12
                    let foundMonth = this.year2months.find(month => month.id === index)
                    col = this.hotInstance.propToCol(`value${i}`)

                    if (foundMonth) {
                        plugin.showColumn(col)
                    } else {
                        plugin.hideColumn(col)
                    }
                }
            }

            this.year1months.length ? this.year1month = true : this.year1month = false
            this.year2months.length ? this.year2month = true : this.year2month = false
            
            this.hotInstance.render()
        },

        showAllQuarters(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let cols = []
            let col = null

            for (let i = 1; i <= 4; i++) {
                col = this.hotInstance.propToCol(`year${year}quarter${i}`)
                cols.push(col)
            }
            
            if (year === 1) {
                if (this.year1quarter) {
                    this.year1quarters = this.quarters
                    plugin.showColumns(cols)
                } else {
                    this.year1quarters = []
                    plugin.hideColumns(cols)
                }
            } else if (year === 2) {
                if (this.year2quarter) {
                    this.year2quarters = this.quarters
                    plugin.showColumns(cols)
                } else {
                    this.year2quarters = []
                    plugin.hideColumns(cols)
                }
            }

            this.hotInstance.render()
        },

        showQuarter(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let array = null
            let col = null

            if (year === 1) {
                array = this.year1quarters
            } else if ( year === 2){
                array = this.year2quarters
            }

            for (let i = 1; i <= 4; i++) {
                let foundQuarter = array.find(quarter => quarter.id === i)
                col = this.hotInstance.propToCol(`year${year}quarter${i}`)
                if (foundQuarter) {
                    plugin.showColumn(col)
                } else {
                    plugin.hideColumn(col)
                }
            }

            this.year1quarters.length ? this.year1quarter = true : this.year1quarter = false
            this.year2quarters.length ? this.year2quarter = true : this.year2quarter = false
            
            this.hotInstance.render()
        },

        showAllSemesters(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let cols = []
            let col = null

            for (let i = 1; i <= 2; i++) {
                col = this.hotInstance.propToCol(`year${year}semester${i}`)
                cols.push(col)
            }
            
            if (year === 1) {
                if (this.year1semester) {
                    this.year1semesters = this.semesters
                    plugin.showColumns(cols)
                } else {
                    this.year1semesters = []
                    plugin.hideColumns(cols)
                }
            } else if (year === 2) {
                if (this.year2semester) {
                    this.year2semesters = this.semesters
                    plugin.showColumns(cols)
                } else {
                    this.year2semesters = []
                    plugin.hideColumns(cols)
                }
            }

            this.hotInstance.render()
        },

        showSemester(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let array = null
            let col = null

            if (year === 1) {
                array = this.year1semesters
            } else if ( year === 2){
                array = this.year2semesters
            }

            for (let i = 1; i <= 2; i++) {
                let foundSemester = array.find(semester => semester.id === i)
                col = this.hotInstance.propToCol(`year${year}semester${i}`)
                if (foundSemester) {
                    plugin.showColumn(col)
                } else {
                    plugin.hideColumn(col)
                }
            }

            this.year1semesters.length ? this.year1semester = true : this.year1semester = false
            this.year2semesters.length ? this.year2semester = true : this.year2semester = false
            
            this.hotInstance.render()
        },

        showYear(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let col = this.hotInstance.propToCol(`year${year}`)

            if (year === 1) {
                if (this.startingYear) {
                    plugin.showColumn(col)
                } else {
                    plugin.hideColumn(col)
                }
            } else if (year === 2) {
                if (this.laterYear) {
                    plugin.showColumn(col)
                } else {
                    plugin.hideColumn(col)
                }
            }

            this.hotInstance.render()
        },

        onResize() {
            this.windowSize = { x: window.innerWidth, y: window.innerHeight }
        },

        async save() {
            this.saving = true

            let payload = { items: [] }

            for (let i = 0; i < this.objectData.length; i++) {
                let object = {}
                this.hotInstance.getDataAtRow(i).forEach((field, index) => {
                    object[this.columns[index].data] = field
                })

                for (const row in this.payload) {
                    object[row] = this.payload[row]
                }

                payload.items.push(object)
            }

            try {
                const res = await this.$http.post(this.$ipProductionPlanning + 'production-planning' + '/mass-save', { ...payload })
                if (res) {
                    this.saving = false
                    this.$toast.success(res.data.msg)
                    this.editedRows = []
                }
            } catch (err) {
                this.saving = false
                this.$fnError(err)
            }
        },

        setRefresh() {
            if (!this.unsavedChanges) {
                this.refresh(false)
            } else {
                this.dialogRefreshGrid = true
            }
        },

        async refresh(save) {
            this.loading = true
            this.dialogRefreshGrid = false

            if (save) {
                let payload = { items: [] }

                for (let i = 0; i < this.objectData.length; i++) {
                    let object = {}
                    this.hotInstance.getDataAtRow(i).forEach((field, index) => {
                        object[this.columns[index].data] = field
                    })

                    for (const row in this.payload) {
                        object[row] = this.payload[row]
                    }

                    payload.items.push(object)
                }

                try {
                    const res = await this.$http.post(this.$ipProductionPlanning + 'production-planning' + '/mass-save', { ...payload })
                    if (res) {
                        this.editedRows = []
                        this.hotInstance = null
                        this.loadGrid(this.payload)
                    }
                } catch (err) {
                    this.$fnError(err)
                }
            } else {
                this.hotInstance = null
                this.loadGrid(this.payload)
            }
        },

        closeGrid() {
            if (!this.unsavedChanges) {
                this.$router.push('/')
            }
        },

        setSelectEvent() {
            if (!this.unsavedChanges) {
                this.selectEvent(false)
            } else {
                this.dialogSelectEvent = true
            }
        },

        closeDialogSelectEvent() {
            this.event = this.backupSelectEvent
            this.dialogSelectEvent = false
        },

        async selectEvent(save) {
            if (save) {
                this.save()
            }

            this.dialogSelectEvent = false
            this.loading = true

            const productOption = this.options.find(option => option.column === 'id_product')
            productOption.items = []
            this.product = null
            this.payload.id_product = null

            const planningGroupOption = this.options.find(option => option.column === 'id_planning_group')
            planningGroupOption.items = []
            this.planningGroup = null
            this.payload.id_planning_group = null

            const plantOption = this.options.find(option => option.column === 'id_plant')
            plantOption.items = []
            this.plant = null
            this.payload.id_plant = null

            this.payload.id_event = this.event.id

            try {
                this.payload.id_account_group_version = this.event.id_account_group_version
                this.payload.id_planning_group_version = this.event.id_planning_group_version
                this.year1 = this.event.fiscal_year
                this.yearMonthInputStart = this.event.year_month_input_start
                this.fiscalYearStartMonth = this.event.fiscal_year_start_month
                this.startingMonthIndex = this.event.fiscal_year_start_month - 1
                this.year2 = this.year1 + 1

                this.setDateColumns()

                let filter = {
                    conditions: [
                        {
                            AndOr: "AND",
                            column: "id_planning_group_version",
                            operator: "=",
                            value: this.payload.id_planning_group_version
                        }
                    ]
                }
                
                const planningGroupOption = this.options.find(option => option.column === 'id_planning_group')
                const planningGroupRes = await this.$http.post(planningGroupOption.endpoint[0] + planningGroupOption.endpoint[1] + '/list-options', { filter })
                if (planningGroupRes.data.rows.length) {
                    planningGroupRes.data.rows.forEach((row, index) => {
                        planningGroupOption.items.push(row)
                        if (index === 0) {
                            this.planningGroup = row
                            this.payload.id_planning_group = this.planningGroup.id
                        }
                    })

                    filter = {
                        conditions: [
                            {
                                AndOr: "AND",
                                column: "id_planning_group",
                                operator: "=",
                                value: this.payload.id_planning_group
                            },
                            {
                                AndOr: "AND",
                                column: "fiscal_year",
                                operator: "=",
                                value: this.year1
                            }
                        ]
                    }

                    const productOption = this.options.find(option => option.column === 'id_product')
                    const productRes = await this.$http.post(productOption.endpoint[0] + productOption.endpoint[1] + '/list-options', { filter })
                    if (productRes.data.rows.length) {
                        productRes.data.rows.forEach((row, index) => {
                            productOption.items.push(row)
                            if (index === 0) {
                                this.product = row
                                this.payload.id_product = this.product.id_product
                            }
                        })
                    } else {
                        return this.noProductsFound()
                    }
                } else {
                    return this.noPlanningGroupsFound()
                }

                const plantOption = this.options.find(option => option.column === 'id_plant')
                const plantRes = await this.$http.post(plantOption.endpoint[0] + plantOption.endpoint[1] + '/list-options', { })
                if (plantRes.data.rows.length) {
                    plantRes.data.rows.forEach((row, index) => {
                        plantOption.items.push(row)
                        if (index === 0) {
                            this.plant = row
                            this.payload.id_plant = this.plant.id
                        }
                    })

                    return this.loadGrid(this.payload)
                } else {
                    return this.noPlantsFound()
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        noEventsFound() {
            this.event = -1
            this.payload.id_event = null
            this.salesChannel = -1
            this.payload.id_sales_channel = null
            this.accountGroupVersion = -1
            this.payload.id_account_group_version = null
            this.planningGroup = -1
            this.payload.id_planning_group = null
            this.product = -1
            this.payload.id_product = null

            this.noDataMessage = 'Cadastre um evento para iniciar o planejamento'
            this.noDataBtn.path = '/event'
            this.noDataBtn.text = 'Cadastrar evento'

            this.loading = false
        },

        setSelectPlanningGroup() {
            if (!this.unsavedChanges) {
                this.selectPlanningGroup(false)
            } else {
                this.dialogSelectPlanningGroup = true
            }
        },

        closeDialogSelectPlanningGroup() {
            this.planningGroup = this.backupSelectPlanningGroup
            this.dialogSelectPlanningGroup = false
        },

        async selectPlanningGroup(save) {
            if (save) {
                this.save()
            }
        
            this.dialogSelectPlanningGroup = false
            this.loading = true
            this.options.find(option => option.column === 'id_product').items = []
            this.product = null
            this.payload.id_product = null
            this.payload.id_planning_group = this.planningGroup.id

            let filter = {
                conditions: [
                    {
                        AndOr: "AND",
                        column: "id_planning_group",
                        operator: "=",
                        value: this.payload.id_planning_group
                    }
                ]
            }

            try {
                const productOption = this.options.find(option => option.column === 'id_product')
                const productRes = await this.$http.post(productOption.endpoint[0] + productOption.endpoint[1] + '/list-options', { filter })
                if (productRes.data.rows.length > 0) {
                    productRes.data.rows.forEach((row, index) => {
                        productOption.items.push(row)
                        if (index === 0) {
                            this.product = row
                            this.payload.id_product = this.product.id_product
                        }
                    })
    
                    this.refresh(false)
                } else {
                    return this.noProductsFound()
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        noPlanningGroupsFound() {
            this.planningGroup = -1
            this.payload.id_planning_group = null
            this.product = -1
            this.payload.id_product = null

            this.noDataMessage = 'Cadastre um grupo de planejamento para iniciar o planejamento'
            this.noDataBtn.path = '/planning-group'
            this.noDataBtn.text = 'Cadastrar grupo de planejamento'

            this.loading = false
        },

        setSelectProduct() {
            if (!this.unsavedChanges) {
                this.setProductId(false)
                this.refresh()
            } else {
                this.dialogSelectProduct = true
            }
        },

        closeDialogSelectProduct() {
            this.product = this.backupSelectProduct
            this.dialogSelectProduct = false
        },

        setProductId(save) {
            if (save) {
                this.save()
            }

            this.hotInstance = null
            this.dialogSelectProduct = false
            this.payload.id_product = this.product.id_product
        },

        noProductsFound() {
            this.product = -1
            this.payload.id_product = null

            this.noDataMessage = 'Relacione um produto ao grupo de planejamento para iniciar'
            this.noDataBtn.path = '/product-sales-planning'
            this.noDataBtn.text = 'Cadastrar produto'

            this.loading = false
        },

        setSelectPlant() {
            if (!this.unsavedChanges) {
                this.selectPlant(false)
            } else {
                this.dialogSelectPlant = true
            }
        },

        closeDialogSelectPlant() {
            this.plant = this.backupSelectPlant
            this.dialogSelectPlant = false
        },

        selectPlant(save) {
            if (save) {
                this.save()
            }

            this.dialogSelectPlant = false
            this.loading = true
            this.payload.id_plant = this.plant.id

            this.refresh()
        },

        noPlantsFound() {
            this.plant = -1
            this.payload.id_plant = null

            this.noDataMessage = 'Cadastre uma planta de produção para iniciar o planejamento'
            this.noDataBtn.path = '/plant'
            this.noDataBtn.text = 'Cadastrar planta de produção'

            this.loading = false
        },

        async exportSheet(){
            this.exporting = true

            let columnsToRemove = this.setColumnsToRemove()

            let payload = {
                columnsToRemove,
                ...this.payload
            }

            try {
                const res = await this.$http.post(this.$ipProductionPlanning + 'production-planning/report', { ...payload })
                if (res) {
                    window.open(res.data.token)
                    this.$toast.success(res.data.msg)
                    this.exporting = false
                }
            } catch (err) {
                this.exporting = false
                this.$fnError(err)
            }
        },

        setColumnsToRemove() {
            let columnsToRemove = []

            // year 1 months
            for (let i = 1; i <= 12; i++) {
                let foundMonth = this.year1months.find(m => m.id === i)

                let index = i < 10 ? `0${i}` : i

                if (!foundMonth) {
                    columnsToRemove.push(`value${index}`)
                }
            }

            // year 2 months
            for (let i = 13; i <= 24; i++) {
                let foundMonth = this.year2months.find(m => m.id === i - 12)

                if (!foundMonth) {
                    columnsToRemove.push(`value${i}`)
                }
            }

            // year 1 quarters
            for (let i = 1; i <= 4; i++) {
                let foundQuarter = this.year1quarters.find(q => q.id === i)

                if (!foundQuarter) {
                    columnsToRemove.push(`year1quarter${i}`)
                }
            }

            // year 2 quarters
            for (let i = 1; i <= 4; i++) {
                let foundQuarter = this.year2quarters.find(q => q.id === i)

                if (!foundQuarter) {
                    columnsToRemove.push(`year2quarter${i}`)
                }
            }

            // year 1 semesters
            for (let i = 1; i <= 2; i++) {
                let foundSemester = this.year1semesters.find(s => s.id === i)

                if (!foundSemester) {
                    columnsToRemove.push(`year1semester${i}`)
                }
            }

            // year 2 semesters
            for (let i = 1; i <= 2; i++) {
                let foundSemester = this.year2semesters.find(s => s.id === i)

                if (!foundSemester) {
                    columnsToRemove.push(`year2semester${i}`)
                }
            }

            // years
            if (!this.startingYear) {
                columnsToRemove.push(`year1`)
            }

            if (!this.laterYear) {
                columnsToRemove.push(`year2`)
            }

            return columnsToRemove
        },

        importSheet(){
            this.$refs.uploader.click()
        },

        async onFileChanged(e) {
            if (e) {
                this.importing = true
                let sheet = e.target.files[0]
                let body = new FormData()
                body.append('spreadsheet', sheet)
    
                try {
                    const uploadRes = await this.$http.post(this.$ipProductionPlanning + 'production-planning' + '/upload-spreadsheet', body, { headers: { 'Content-Type': 'multipart/form-data' }})
                    if (uploadRes) {
                        const massEffectRes = await this.$http.post(this.$ipProductionPlanning + 'production-planning' + '/mass-effect', { ...uploadRes.data.id })
                        if (massEffectRes) {
                            this.$toast.success(massEffectRes.data.msg)
                            this.$refs.uploader.value = null
                            this.importing = false
                            this.refresh(false)
                        }
                    }
                } catch (err) {
                    this.$refs.uploader.value = null
                    this.importing = false
                    this.$fnError(err)
                }
            }
        },

        async approvalSelect (option) {
            const payload = {
                id_event: this.selectedEvent,
                id_planning_group: this.selectedPlanningGroup,
                justification: this.justification
            }
            try {
                if (option === 1) {
                    const res = await this.$http.post(this.$ipApprovalFlow + 'event-status-planning-group/approve', { ...payload })
                    if (res) {
                        this.$toast.success(res.data.msg)
                    }
                } else {
                    const res = await this.$http.post(this.$ipApprovalFlow + 'event-status-planning-group/disapprove', { ...payload })
                    if (res) {
                        this.$toast.success(res.data.msg)
                    }
                }
            } catch (err) {
                this.$fnError(err)
            }
            this.dialogApproval = false
            this.justification = ''
            await this.approvalListLevels()
            this.approvalHistory()
            this.refresh()
        },

        async approvalListLevels () {
            let unapprovedLevels = []
            const payload = {
                id_event: this.event.id,
                id_planning_group: this.selectedPlanningGroup
            }

            if (!payload.id_event || !payload.id_planning_group) {
                return
            }

            try {
                const res = await this.$http.post(this.$ipApprovalFlow + 'event-status-planning-group/list-levels', { ...payload })
                if (res.data.rows.length > 0) {
                    this.levels = res.data.rows
                    this.levels.forEach((level) => {
                        level.date ? level.date = moment(level.date).format('DD/MM/YYYY HH:mm:ss') : false
                        level.id_status === 5 || !level.id_status ? unapprovedLevels.push(level) : false
                    })
                    unapprovedLevels.length < 1 ? this.actualLevel.id_level = 'Todos os níveis aprovados' : this.actualLevel = unapprovedLevels[0]
                    this.userGroups.forEach((group) => {
                        if (group.id_group === this.actualLevel.id_group) {
                            this.disableApprove = false
                        }
                    })
                    await this.getlevels()
                } else {
                    this.readOnly = false
                }
            } catch (err) {
                this.$fnError(err)
            }
        }, 

        async approvalHistory () {
            const payload = {
                id_event: this.event.id,
                id_planning_group: this.selectedPlanningGroup
            }

            if (!payload.id_event || !payload.id_planning_group) {
                return
            }

            try {
                const res = await this.$http.post(this.$ipApprovalFlow + 'event-status-planning-group/list-history', { ...payload })
                if (res) {
                    this.approvals = res.data.rows
                    this.approvals.forEach((approval) => {
                        approval.date ? approval.date = moment(approval.date).format('DD/MM/YYYY HH:mm:ss') : false
                    })
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        async listUserGroups () {
            const payload = {
                filter: {
                    conditions: [
                        {
                            AndOr: "AND",
                            column: "id_user",
                            operator: "=",
                            value: this.getUser.id_user
                        }
                    ]
                }
            }
            try {
                const res = await this.$http.post(this.$ipSecurity + 'user-group/list', { ...payload })
                if (res) {
                    this.userGroups = res.data.rows
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        async getlevels () {
            const payload = {
                filter: {
                    conditions: [
                        {
                            AndOr: "AND",
                            column: "id_approval_flow_level",
                            operator: "=",
                            value: this.actualLevel.id_approval_flow_level
                        }
                    ]
                }
            }
            try {
                const res = await this.$http.post(this.$ipApprovalFlow + 'approval-flow-level/list', { ...payload })
                if (res) {
                    if (res.data.rows.length > 0) {
                        res.data.rows[0].change_values === 1 ? this.actualLevel.readOnly = false : this.actualLevel.readOnly = true
                    }
                    if (this.actualLevel.id_level > 0){
                        this.readOnly = this.actualLevel.readOnly
                        this.readOnly === true ? this.$toast.warning('Este nível de aprovação não permite alterações') : false
                    } else {
                        this.levels.length > 0 ? this.readOnly = true : this.readOnly = false
                        if (this.readOnly) {
                            this.$toast.success('Este evento está com todos os níveis aprovados')
                        }
                    }
                }
            } catch (err) {
                this.$fnError(err)
            }
        }
    },
}
</script>

<style src="@/../public/styles/handsontable/handsontable.full.css"></style>
