<template>
    <div id="ProductionPlanning">
        <v-container fluid app>
            <ZnapHotGrid
                :options="options"
                :tableName="tableName"
                :tableIcon="tableIcon"
                :tableDescription="tableDescription"
            >
            </ZnapHotGrid>
        </v-container>
    </div>
</template>

<script>
import Vue from 'vue'
import ZnapHotGrid from '@/components/znap/HotGrid.vue'

export default {
    name:'ProductionPlanning',

    components:{
        ZnapHotGrid
    },

    data: () => ({
        tableName: 'Planejamento por produto',
        tableIcon: 'mdi-finance',
        tableDescription: 'Forneça uma descrição para esta tabela',
        options: [
            {
                column: 'id_event',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Evento',
                required: true,
                filterable: false,
                endpoint: [ Vue.prototype.$ipEvent, 'event' ]
            },
            {
                column: 'id_planning_group',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Grupo de planejamento',
                required: true,
                filterable: true,
                endpoint: [ Vue.prototype.$ipApprovalFlow, 'planning-group' ]
            },
            {
                column: 'id_product',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Produto',
                required: true,
                filterable: true,
                endpoint: [ Vue.prototype.$ipProduct, 'product-production-planning' ]
            },
            {
                column: 'id_plant',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Planta de produção',
                required: true,
                filterable: true,
                endpoint: [ Vue.prototype.$ipOrganization, 'plant' ]
            }
        ]
    }),
}
</script>

<style scoped>
</style>